@mixin th_style {
	position: relative;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
	letter-spacing: normal;
	text-transform: uppercase;
	text-overflow: ellipsis;
	border-bottom: 2px solid var(--tableBorder);
}

@mixin th_text {
	color: $tableHeaderText;
	font-size: 0.9rem;
	font-weight: 600;
	overflow: hidden;
	min-width: 0;
	text-align: left;
	white-space: nowrap;
	letter-spacing: normal;
	text-transform: uppercase;
	text-overflow: ellipsis;
}

@mixin tr_style {
	color: $tableRowText;
	font-variant-numeric: tabular-nums;

	&:nth-child(odd) {
		--tableRowBg: #{$tableRowOdd};
	}

	&:nth-child(even) {
		--tableRowBg: #{$tableRowEven};
	}

	&:not(:last-of-type) {
		border-bottom: 1px solid $tableRowBorderBottom;
	}

	&:focus,
	&:hover {
		color: $tableRowHoverText;
		--tableRowBg: #{$tableRowHoverBg};

		.one-datatable__column--sticky {
			background-color: inherit;
		}
	}
}