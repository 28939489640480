@import "base";


.one-input {
	@include flexChild(none);
	position: relative;
	display: inline-flex;
	align-items: center;
	font-weight: var(--inputWeight, normal);
	line-height: $lineHeight;
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	vertical-align: bottom;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-bottom: 1px solid $inputBorder;
	border-radius: 0;
	background-color: transparent;

	&:hover:not(:focus):not(:focus-within):not(:disabled):not(.one-input--readonly) {
		border-bottom: 1px solid $darkGray;
	}

	&:active,
	&:focus,
	&:focus-within {
		border-bottom: 1px solid $info;

		.one-input__decorator {
			@include flex();
		}
	}

	&.one-input--wrap {
		overflow-y: auto;
		white-space: pre-wrap;
	}

	p-autocomplete,
	.p-autocomplete {
		width: 100%;
		@include flexChild(auto);
		@include flex();
	}

	.one-icon-autocomplete__button,
	.p-inputnumber-button,
	.one-input__button--inline,
	.p-autocomplete-loader,
	.p-datepicker-trigger,
	.p-autocomplete-clear-icon,
	.one-field-autocomplete-clear,
	.one-field-autocomplete-dropdown,
	.p-autocomplete-dropdown,
	.p-multiselect-trigger {
		--buttonClickAreaSize: var(--buttonHeight, var(--lineHeight));
		--borderRadius: 0;
		@include flexChild(none);
		@include one-button();
		@include one-button--icon-only();
		align-self: flex-end;
		color: $inputLabel;
		border-bottom: none;
		position: relative;
		top: unset;
		margin: 0 0 0 auto;
	}

	.p-autocomplete-loader + .p-autocomplete-dropdown,
	.p-autocomplete-options,
	.p-autocomplete-clear-icon {
		display: none;
	}

	&:focus,
	&:focus-within,
	&:hover {
		.p-autocomplete-options,
		.p-autocomplete-clear-icon {
			display: flex;
		}
	}

	&.one-input--readonly,
	&:disabled {
		color: $inputValueReadOnly;
		border-bottom: 1px dashed $inputBorder;

		.p-autocomplete-dropdown,
		.p-autocomplete-options,
		.p-autocomplete-clear-icon {
			display: none;
		}
	}

	&:not(.one-input--readonly) {

		.p-autocomplete-link-container,
		.p-autocomplete-input {
			width: 100%;
		}

		.p-autocomplete_link {
			width: auto;
			max-width: 82%;
		}
	}

	.p-autocomplete-input {
		@include flexChild(auto);
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
	}

}

.one-input--inline {
	@include flex();
}

.one-input__group {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-end;
	line-height: $lineHeight;
	min-height: $sectionRowHeight;
	text-align: left;
	// for debug... do not delete next line
	/* outline: thin solid red; */

	&.one-input__group--reverse {
		flex-direction: column-reverse;
	}
}

.one-input__decorator {
	display: none;
	font-weight: var(--inputWeight, normal);
	color: $inputLabel;
	margin-right: $elementSpacingSmall;
}

.one-input__linkify-button {
	height: 1em;
}

/* both Label types */
@mixin labelStyle() {
	color: $inputLabel;
	font-size: $inputLabelSize;
	transform: none;
	letter-spacing: normal;
}

.p-inline-label,
.p-float-label {
	& .one-label,
	& > label {
		display: inline-flex;
		align-items: flex-end;
		font-weight: var(--inputWeight, normal);
		color: $inputLabel;
		line-height: 1em;
		height: $inputLabelSize;
		transition: 0.3s ease all;
	}

	&.p-state-error {

		.p-checkbox-label,
		label {
			color: $negative !important;
		}
	}

	&:focus-within label {
		color: $info;
	}
}

/* inline Label */
.p-inline-label {
	@include flex();
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding-top: $inputLabelSize;

	& > label:first-child {
		line-height: $lineHeight;
		height: initial;
		margin-right: $elementSpacingSmall;
	}

	.one-input__decorator {
		@include flex();
	}
}

/* Floating Label */
.p-float-label {

	& .one-label,
	& > label {
		width: calc(100% - (#{$lineHeight} * 2));
		transform: translatey(calc(50% + #{$lineHeight} / 2));
	}

	& .p-inputwrapper-filled,
	& > .p-inputwrapper-focus,
	&:focus-within,
	&.p-state-filled {

		& .one-label,
		& > label {
			@include labelStyle();
		}

		.one-input__decorator {
			@include flex();
		}
	}
}

.one-label {
	@include labelStyle();
}

.p-centered-label {
	& > label {
		@include flexChild(auto);
		display: block;
		width: 100%;
		text-align: center;
	}
}

.one-input__message {
	width: 100%;

	&.one-input__message--min-height {
		min-height: 1rem;
	}
}