/*** Paginator ***/
p-paginator {
	@include flexChild(none);
	width: 100%;
}

.p-paginator {
	display: flex;
	align-items: center;
	color: $otherButtonText;
	border-top: 2px solid $tableBorder;

	.p-paginator-left-content,
	.p-paginator-right-content {
		@include flexChild(none);
		margin: 0;

		@include phone() {
			display: none;
		}
	}

	.p-paginator-current {
		line-height: normal;

		@include phone() {
			display: none;
			margin: 0;
		}
	}

	.p-paginator-pages {
		display: flex;
	}

	.p-paginator-page,
	.p-paginator-first,
	.p-paginator-last,
	.p-paginator-next,
	.p-paginator-prev {
		@include one-button();
		@include one-button--icon-only();
		position: relative;
		margin-right: $elementSpacingSmall;

		&.p-highlight {
			font-weight: 600;
			color: $info;
		}

		&.p-disabled {
			color: $lightGray;
			cursor: not-allowed;
		}
	}

	.p-paginator-page:not(.p-highlight) {
		@include phone() {
			display: none;
		}
	}

	.p-paginator-rpp-options {

		.p-dropdown-trigger .p-icon-wrapper {
			height: 1em;
		}

		@include phone() {
			display: none;
			margin: 0;
		}
	}
}
